<template>
  <div class="mod-green_application_information}">
    <div>
      <!-- <div>用户基本信息</div> -->
      <el-card>
        <div slot="header" class="clearfix">
          <span>{{ title }}</span>
          <span v-if="showHeaderButton">
            <el-button
              style="float: right; padding: 3px 3px"
              type="text"
              v-if="$hasPermission('miaoins')"
              @click="editorApplicationInformation()"
              >编辑</el-button
            >
            <el-button
              style="float: right; padding: 3px 3px"
              type="text"
              v-if="$hasPermission('miaoins')"
              @click="saveApplicationInformation()"
              >保存</el-button
            >
          </span>
          <!-- <span>{{ greenss }}</span> -->
        </div>
        <div class="basic_information">
          <el-form
            :inline="true"
            :disabled="disabled"
            :model="getGreenApplicationInformation"
            ref="getGreenApplicationInformation"
            :rules="dataRule"
            class="demo-form-inline"
          >
            <!-- <el-form-item label="案件号">
              <el-input
                v-model="getGreenApplicationInformation.greenNo"
                placeholder="案件号"
              ></el-input>
            </el-form-item> -->
            <div v-show="showIllness">
              <el-form-item label="预约出院日期" prop="applyHospitalStartDate">
                <el-date-picker
                  value-format="yyyy-MM-dd"
                  v-model="
                    getGreenApplicationInformation.applyHospitalStartDate
                  "
                  type="date"
                  placeholder="选择日期"
                ></el-date-picker>
              </el-form-item>

              <el-form-item label="地区" prop="selectedOptions">
                <el-cascader
                  v-model="getGreenApplicationInformation.selectedOptions"
                  :options="options"
                  :props="{ expandTrigger: 'hover' }"
                  clearable
                  @change="handleChange"
                ></el-cascader>
              </el-form-item>
              <el-form-item
                label="服务地址"
                prop="address"
                v-show="showAddress"
              >
                <el-input
                  v-model="getGreenApplicationInformation.address"
                  placeholder="服务地址"
                ></el-input>
              </el-form-item>
              <el-form-item label="申请医院">
                <el-input
                  v-model="getGreenApplicationInformation.applyHospital"
                  placeholder="申请医院"
                ></el-input>
              </el-form-item>
              <el-form-item label="申请科室">
                <el-input
                  v-model="getGreenApplicationInformation.applyDepartment"
                  placeholder="申请科室"
                ></el-input>
              </el-form-item>
              <!-- <el-form-item label="意向医生">
                <el-input
                  v-model="getGreenApplicationInformation.doctorName"
                  placeholder="意向医生"
                ></el-input>
              </el-form-item> -->
              <el-form-item label="当前绿通状态">
                <el-select
                  disabled
                  v-model="getGreenApplicationInformation.greenStatus"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in greenStatusOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="病情描述">
                <el-input
                  type="textarea"
                  v-model="getGreenApplicationInformation.illnessNote"
                  placeholder="病情描述"
                ></el-input>
              </el-form-item>
            </div>
            <div v-if="showAppealDesc">
              <el-form-item label="诉求描述">
                <el-input
                  type="textarea"
                  v-model="getGreenApplicationInformation.appealDesc"
                  placeholder="诉求描述"
                ></el-input>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { regionData } from 'element-china-area-data';
import debounce from 'lodash/debounce';
import { treeDataTranslate } from "@/utils";
export default {
  data() {
    return {
      header_button: true,
      showHeaderButton: true,
      disabled: true,
      showAddress: false,
      applyTypeData: [
        { id: 1, name: '住院绿通' },
        { id: 2, name: '门诊绿通' },
        { id: 3, name: '院中陪护' },
      ],
      greenStatusOptions: [
        { id: 0, name: '初审中' },
        { id: 1, name: '初审不通过' },
        { id: 2, name: '需求确认' },
        { id: 3, name: '预约中' },
        { id: 4, name: '预约成功' },
        { id: 6, name: '结案' },
        { id: 7, name: '受理中' },
      ],
      applyHospitalData: [], // 医院
      applyDepartmentData: [], // 科室
      getGreenApplicationInformation: {
        id: '',
        greenNo: '',
        type: '',
        applyHospitalStartDate: '',
        applyHospitalEndDate: '',
        applyHospital: '',
        applyDepartment: '',
        doctorName: '',
        greenStatus: '',
        illnessNote: '',
        selectedOptions: [],
      },
      rightCode: '',
      ticketId: '',
      options: [],
      selectedOptions: [],
      title: '申请信息',
      showIllness: true,
      showAppealDesc: false,
    };
  },
  computed: {
    dataRule() {
      return {
        applyHospitalStartDate: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
        applyHospitalEndDate: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
        selectedOptions: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
        address: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
      };
    },
  },
  components: {
    // AddOrUpdate
  },
  created() {
    // this.getGreenApplicationInformations()
    // this.getapplyHospitalData()
    // this.getapplyDepartmentData()
  },
  methods: {
    greenFun(params) {
      console.log('点击事件11', params);
      this.getGreenApplicationInformation = {
        ...this.getGreenApplicationInformation,
        ...params.entityDetails,
      };
      this.ticketId = params.id;
      let regionData = [];
      regionData.push(params.entityDetails.province);
      regionData.push(params.entityDetails.city);
      regionData.push(params.entityDetails.district);
      this.getGreenApplicationInformation.selectedOptions = regionData;
      console.log(regionData);
      if (params.status === 2 || params.status === 0) {
        this.showHeaderButton = false;
      } else {
        this.showHeaderButton = true;
      }
      this.rightCode = params.rightCode;
      this.title = params.rightName;
      if (this.rightCode === 'ASSIST_LEAVE_HOSPITAL') {
        this.showIllness = true;
      }
      this.getAreaList()
     
    },
     getAreaList() {
      this.$http
        .get(`/sys/region/tree`)
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          let dataList = res.data;
          for (let i in dataList) {
            dataList[i].value = dataList[i].id;
            dataList[i].label = dataList[i].name;
          }

          let dataList2 = treeDataTranslate(dataList);
          this.options = dataList2
        })
        .catch(() => {});
    },
    // 编辑
    editorApplicationInformation() {
      this.header_button = false;
      this.disabled = false;
      this.$emit('fatherMethod');
    },
    // 保存
    saveApplicationInformation() {
      
      if (!this.rightCode === 'ASSIST_LEAVE_HOSPITAL') {
        if (!this.selectedOptions) {
          return this.$message.error('地区不能为空!');
        }
      } else {
        if (!this.getGreenApplicationInformation.illnessNote) {
          return this.$message.error('需求描述不能为空!');
        }
      }

      // this.$refs['getGreenApplicationInformation'].validate((valid) => {
      //   if (!valid) {
      //     return false;
      //   }
      this.getGreenApplicationInformation.ticketId = Number(this.ticketId);
      this.$http
        .post(
          `/healthbusiness/health-green-channel/update`,
          this.getGreenApplicationInformation
        )
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          this.$message({
            message: '保存成功',
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false;
              this.header_button = true;
              this.disabled = true;
              // this.getGreenApplicationInformations();
            },
          });
        })
        .catch(() => {});
      // });
    },
    handleChange(e) {
      console.log(this.selectedOptions);
      this.getGreenApplicationInformation.province = e[0];
      this.getGreenApplicationInformation.city = e[1];
      this.getGreenApplicationInformation.district = e[2];
    },
  },
};
</script>
<style scoped>
.clearfix {
  font-size: 20px;
  font-weight: 700;
}
.el-card {
  border-top: 3px solid rgba(3, 165, 151, 0.616);
}
.el-card /deep/.el-card__body {
  padding-bottom: 0px;
}
.basic_information {
  /* width: 90%; */
  /* border: 1px solid #cccccc; */
}
.input {
  display: inline-block;
}
.demo-form-inline {
  margin-left: 20px;
}
.el-form--inline .el-form-item {
  margin-right: 30px;
}
</style>
